<template>
  <b-col sm="6" md="4">
    <b-card body-class="p-3" border-variant="primary">
      <b-card-title v-b-popover.hover.top="booklet.name" class="text-truncate">
        {{ booklet.name }}
      </b-card-title>
      <b-row>
        <b-col xl="6">
          <b-card-text class="small text-muted mb-2">
            <div>{{ booklet.round.name }}</div>
            <div>{{ roundDurationString(booklet.round) }}</div>
            <div>Verejné: <CheckOrCross :value="booklet.visible" /></div>
          </b-card-text>
        </b-col>

        <b-col xl="6">
          <b-button
            block
            variant="outline-dark"
            :disabled="!booklet.printable_version"
            @click="downloadBooklet()"
          >
            <div
              v-b-popover.hover.top="
                booklet.printable_version
                  ? ''
                  : 'PDF verzia zatiaľ nebola nahratá.'
              "
            >
              <font-awesome-icon :icon="['fas', 'print']" /> PDF
            </div>
          </b-button>
          <b-button
            block
            class="mt-2"
            variant="outline-dark"
            :to="{ name: 'booklet-change', params: { bookletId: booklet.id } }"
          >
            Upraviť
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </b-col>
</template>

<script>
import { apiBooklets, CheckOrCross, utils } from "frontend-common";
import constants from "@/constants";

export default {
  name: "BookletStatus",
  components: {
    CheckOrCross,
  },
  mixins: [apiBooklets],
  props: {
    booklet: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    createdString() {
      return utils.dateTimeToString(this.booklet.created);
    },
  },
  mounted() {},
  methods: {
    roundDurationString(round) {
      return utils.datesToDuration(round.start_time, round.end_time);
    },
    downloadBooklet() {
      // Note: this has to be a download, not a link-open due required auth headers
      this.$root.downloadFile(
        {
          url: this.booklet.printable_version,
          cache: { maxAge: constants.maxAgeDefaults.mid },
        },
        utils.sanitizeFilename(
          `${this.booklet.name}-${this.booklet.round.contest.name}-` +
            `${this.booklet.round.contest.group}`,
        ) + ".pdf",
        constants.mimeTypes.PDF,
      );
    },
    async generatePdf() {
      try {
        await this.apiGeneratePdfBooklet(this.booklet.id);
        this.$root.infoToast(
          "Spustilo sa generovanie PDF! Môže to trvať aj 10s",
        );
      } catch {
        this.$root.dangerToast("Niečo sa pokazilo!");
      }
    },
  },
};
</script>

<style scoped></style>
