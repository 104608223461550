import { render, staticRenderFns } from "./BookletStatus.vue?vue&type=template&id=7c2ecb19&scoped=true&"
import script from "./BookletStatus.vue?vue&type=script&lang=js&"
export * from "./BookletStatus.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c2ecb19",
  null
  
)


    import installComponents from "!/Users/jurajpetras/dev/pmat/web/frontend-internal/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BCardTitle, BCardText, BCol, BButton, BRow, BCard} from 'bootstrap-vue'
    installComponents(component, {BCardTitle, BCardText, BCol, BButton, BRow, BCard})
    


    import installDirectives from "!/Users/jurajpetras/dev/pmat/web/frontend-internal/node_modules/bootstrap-vue-loader/lib/runtime/installDirectives.js"
    import {VBPopover} from 'bootstrap-vue'
    installDirectives(component, {'b-popover': VBPopover})
    

export default component.exports