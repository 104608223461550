<template>
  <div>
    <b-button-group v-b-popover.hover.bottom.window="'zobrazovaný seminár'">
      <b-button
        v-for="option of options"
        :key="option.value"
        :variant="buttonVariant(option)"
        class="no-focus"
        @click="
          selectOption(option);
          $event.target.blur();
        "
      >
        {{ option.text }}
      </b-button>
    </b-button-group>
  </div>
</template>

<script>
import constants from "@/constants";

export default {
  name: "SeminarySwitcher",
  components: {},
  mixins: [],
  props: {},
  data() {
    return {
      options: [
        {
          text: "všetko",
          value: constants.contestGroups.ALL,
          variant: "info",
          outlineVariant: "outline-info",
        },
        {
          text: "Pikomat",
          value: constants.contestGroups.PIKOMAT,
          variant: "pmat-blue",
          outlineVariant: "outline-pmat-blue",
        },
        {
          text: "Pikofyz",
          value: constants.contestGroups.PIKOFYZ,
          variant: "pmat-orange",
          outlineVariant: "outline-pmat-orange",
        },
        {
          text: "Terabio",
          value: constants.contestGroups.TERABIO,
          variant: "pmat-green",
          outlineVariant: "outline-pmat-green",
        },
      ],
    };
  },
  computed: {
    selectedOption() {
      return this.options.find(
        (option) => option.value === this.$root.storage.selectedContestGroup,
      );
    },
  },
  mounted() {},
  methods: {
    selectOption(option) {
      this.$root.storage.selectedContestGroup = option.value;
    },
    buttonVariant(option) {
      return this.selectedOption.value === option.value
        ? option.variant
        : option.outlineVariant;
    },
  },
};
</script>

<style scoped>
.no-focus:focus {
  box-shadow: none !important;
}
</style>
