<template>
  <b-container class="mt-5 mb-3">
    <b-row>
      <b-col>
        <h1>Tvorba zadaní a vzorákov</h1>
      </b-col>
      <b-col xl="auto">
        <SeminarySwitcher class="mt-1 mb-2" />
      </b-col>
    </b-row>
    <p>
      Pozor, zatiaľ vie každý vedúci upravovať všetky zadania, preto konaj
      opatrne.
    </p>
    <LoadingSpinner :is-loaded="isLoaded">
      <div
        v-for="contest of displayedContests"
        :key="`contest-${contest.id}`"
        class="mt-3 mx-md-3"
      >
        <h2>{{ contest.group }} {{ contest.name }}</h2>
        <b-row>
          <BookletStatus
            v-for="booklet of booklets[contest.id]"
            :key="'booklet-' + booklet.id"
            class="my-2"
            :booklet="booklet"
          />
        </b-row>
        <hr />
      </div>
    </LoadingSpinner>
  </b-container>
</template>

<script>
import { apiBooklets, apiContests, LoadingSpinner } from "frontend-common";
import BookletStatus from "@/components/BookletStatus.vue";
import contestGroupFilter from "@/mixins/contestGroupFilter";
import SeminarySwitcher from "@/components/SeminarySwitcher.vue";

export default {
  name: "BookletOverview",
  components: {
    LoadingSpinner,
    BookletStatus,
    SeminarySwitcher,
  },
  mixins: [apiBooklets, apiContests, contestGroupFilter],
  props: {},
  data() {
    return {
      isLoaded: false,
      allContests: [],
      // Booklets are stored in this structure `booklets: {contestId: [booklets, ...], ...}`
      booklets: {},
    };
  },
  computed: {
    displayedContests() {
      return this.contestGroupFilter(this.allContests, "group");
    },
  },
  watch: {},
  mounted() {
    this.apiContests()
      .then((contests) => {
        this.allContests = contests.reverse();
        return Promise.all(
          contests.map((contest) =>
            this.apiBooklets(contest.id).then(
              (booklets) => (this.booklets[contest.id] = booklets),
            ),
          ),
        );
      })
      .finally(() => (this.isLoaded = true));
    // TODO we should implement pagination in this view (by extracting it to a nice component)
  },
  methods: {},
};
</script>

<style scoped></style>
